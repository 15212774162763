/**
* Template Name: Budaty - v3.10.0
* Template URL: 
* Author: 
* License:
*/
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neuton:wght@400;700&family=Quicksand:wght@500&family=Roboto:wght@300&display=swap');
body {
  display:inline-block;
  font-family: "Open Sans", sans-serif !important;
  background: #0c0b09;
  color: #fff !important;
  /* overflow-y: auto !important; */
  overflow: auto !important;
  width: 100%;
}

:root {
    scroll-behavior: smooth;
    --primary: #cda45e;
    --primaryMenu: #;
    --blue-dark: #000e1f;
    --light-blue: #54C0E8;
    --dark-grey: #585858;
    --sombra: 0 0 13px 0 rgba(185,185,185, .25);
    --gris: #E6E4E1;
    --marron: #625b4b;
    --blue: #002856;
    --white: #fff;
    --letter-gris: rgba(255, 255, 255, 0.5);
}

/*h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quicksand', sans-serif;
}*/


.bodyMenu {
  width: 100vw;
  background: #0c0b09 !important;
}




.white{
  color: var(--white) !important;
}
a {
  color: #cda45e ;
  text-decoration: none !important;
}



a:hover {
  color: #d9ba85;
  text-decoration: none !important;
}


/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #1a1814;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1a1814;
  border-top-color: #cda45e;
  border-bottom-color: #cda45e;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.margin{
  margin: 15px !important;
}

.ubic{
  letter-spacing: 2px !important;
  /* text-transform: uppercase ; */
  font-size: 13px !important;
  padding-left: 12px !important;
}

.actual{
  margin: 6px 0 5px 0 !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 15px;
  bottom: 90px;
  z-index: 996;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
  border: 2px solid #cda45e;
  background-color: var(--dark-grey);
}

.back-to-top i {
  font-size: 28px;
  color: #cda45e;
  line-height: 0;
}

.back-to-top:hover {
  background: #cda45e;
  color: #1a1814;
}

.back-to-top:hover i {
  color: #444444;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  #navbar.active {
    right: 0px;
}

.eslogan{
  font-size: 32px !important;
  padding: 5px;
  line-height: 0.9 !important;
}

#navbar {
  display: flex !important;
  padding: 80px 0 0 10px;
  transition: 0.3s;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 300px;
  right: -300px;
  background-color: #28251f;
  box-shadow: 0 40px 60px rgb(0 0 0 / 2%);
  z-index: 999999 !important;
  transition: all 0.3s ease-in-out;
   overflow: visible; 
}
#navbar li {
  margin-bottom: 25px;
}
#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#close {
  display: initial !important;
  position: absolute;
  top: 30px;
  left: 30px;
  color: #fff;
  font-size: 24px;
}

.box{
  right: 10vw !important;
}

}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info i {
  font-style: normal;
  color: #d9ba85;
}

#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

#topbar .languages ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #cda45e;
}

#topbar .languages ul a {
  color: white;
}

#topbar .languages ul li+li {
  padding-left: 10px;
}

#topbar .languages ul li+li::before {
  display: inline-block;
  padding-right: 10px;
  color:  var(--letter-gris);
  content: "/";
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#headerMenu {
  background: rgba(12, 11, 9, 0.6);
  border-bottom: 1px solid rgba(12, 11, 9, 0.6);
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  top: 40px;
}

#headerMenu.header-scrolled {
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #37332a;
}

#headerMenu .logoo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase !important;
  font-family: "Poppins", sans-serif !important;
}

#headerMenu .logoo a {
  color: #fff;
}

#headerMenu .logoo img {
  max-height: 40px;
}



/*--------------------------------------------------------------
# Book a table button Menu
--------------------------------------------------------------*/
.book-a-table-btn {
  margin: 0 0 0 15px;
  border: 2px solid #cda45e;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
}

.book-a-table-btn:hover {
  background: #cda45e;
  color: #fff;
}

@media (max-width: 992px) {
  .book-a-table-btn {
    margin: 0 15px 0 0;
    padding: 8px 20px;
  }
}


@media (min-width: 550px) {
 
  #cart.confirm {
    margin: 0 250px !important;
  }

  .boxes {
    width: 50% !important;
  }

.end{
  width: 50%;
}

#comprar {
  margin: 0 5% !important;
}
.subtotal {
  margin-left: 15%;
}
.subtotal {
  padding: auto !important;
}

#pedido {
 width: 70% !important;
  overflow-y: auto !important;
}
#carrito{
  align-items: center !important;
 
}

#add {
  margin-right: 8px;

}

.col-lg-8{
  text-align: left !important;
}
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #d9ba85;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #444444;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #cda45e;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {

  .mobile-nav-toggle {
    display: block;
  }
  .stats-counter {
    background-attachment: fixed;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
    url(https://images.unsplash.com/photo-1485182708500-e8f1f318ba72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2110&q=80)
     center !important;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
 
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #1a1814;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #cda45e;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #cda45e;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
/* #hero {
  width: 100%;
  height: 100vh;
  background: url("https://app.clickmenu.com.ar:8083/img/fondohome.png") top center;
  background-size: cover;
  position: relative;
  padding: 0;
  background-size: cover !important;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */

#hero .container {
  padding-top: 110px;
}

 @media (max-width: 440px) {
  #hero .container {
    padding-top: 175px !important;
  }

}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700 !important;
  line-height: 56px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#hero h1 span {
  color: #cda45e;
  font-weight: 700 !important;
}

#hero h2 {
  color: #eee;
  margin-bottom: 10px 0 0 0;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
}

#hero .btns {
  margin-top: 30px;
}

#hero .btn-menu,
#hero .btn-book {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.3s;
  line-height: 1;
  color: white;
  border: 2px solid #cda45e;
  margin: 8px;
}

#hero .btn-menu:hover,
#hero .btn-book:hover {
  background: #cda45e;
  color: #fff;
}

#hero .btn-book {
  margin-left: 15px;
}

#hero .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#cda45e 50%, rgba(205, 164, 94, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: relative;

}

#hero .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(205, 164, 94, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#hero .play-btn:hover::after {
  border-left: 15px solid #cda45e;
  transform: scale(20);
}

#hero .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

.focus{
  cursor: pointer;
}

#add{
  margin-right: 20px;
  cursor: pointer;
  font-size: 30px;
  color: #eee;
}

#addVisit{
  margin-right: 20px;
  cursor: pointer;
  font-size: 30px;
  color: #cda45e;
}



.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  #hero .play-btn {
    margin-top: 30px;
  }
}

@media (max-height: 500px) {
  
 
  #hero {
    height: auto;
  }

  #hero .container {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}

@media (max-width: 500px) {
  .menu .menu-content::after {
    content: "................................................." !important;
  }

  .gridmedia{
    display: grid !important;
 
  }
  .menu-content span{
    left: 11px !important;
    width: 60px;
  }
  #add{
    margin-right: 8px;
  }

  #addVisit{
    margin-right: 8px;
  }

  #carrito {
  padding: 0px;

  }

  #comprar{
    margin-left: -14px;

  }

  .menuCM {
    width: 100%;
    height: 90vh !important;
    background-position-x: -450px !important;
  }


  
}


@media (max-width: 400px) {
  #comprar {
    margin-left: 0px !important;
  }
  .detalles{
  padding: 5px 25px !important;
  }


 
  #carrito #comprar .duo {
   margin: 0; 
  }
  .menu .menu-content::after {
    content: ".................................." !important;
  }
  #pedido {
    margin-left: -45px;
    width: auto !important;
}
.boxes {

  width: 70% !important;
}

#cart.confirm {
  margin: 0px 40px !important;
}
}

@media (max-width: 340px){
#cart.confirm {
    margin: 0px 8% !important;
}
#comprar .subtotal {
  font-size: 18px;
  padding:10px 25px !important; 
 }
 .decimal {
  font-size: 12px;
  margin-left: -26px !important;
}
}

@media (max-width: 768px) {

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.sectionMenu {
  padding: 85px 0;
  background-color: rgb(12, 11, 9);
}

.section-bg {
  background-color: #1a1814;
}

.section-title {
  padding-bottom: 14px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 4px 10px;
}

.section-title p {
  margin: 0 2px;
  font-size: 36px;
  font-weight: 700;
  font-family:  "Poppins", serif;
  color: #cda45e;
}



/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #1d1b16;
  margin-top: 110px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 98px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #37332a;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  background: url("https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80") center center;
  background-size: cover;
  position: relative;
  padding: 80px 0;
}

.about:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .about-img {
  position: relative;
  transition: 0.5s;
}

.about .about-img img {
  max-width: 100%;
  border: 4px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.about .about-img::before {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
  height: 60px;
  z-index: 1;
  content: "";
  border-left: 5px solid #cda45e;
  border-top: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img::after {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  z-index: 2;
  content: "";
  border-right: 5px solid #cda45e;
  border-bottom: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img:hover {
  transform: scale(1.03);
}

.about .about-img:hover::before {
  left: 10px;
  top: 10px;
}

.about .about-img:hover::after {
  right: 10px;
  bottom: 10px;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cda45e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .about {
    background-attachment: fixed;
  }

  #comprar{
    width: 100% !important;
   
  }
  

.subtotal {
  padding: auto !important;
  margin-left: 6% !important;
}

  button.end {
    width: 35% !important;
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  background: #1a1814;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #cda45e;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.8);
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover {
  background: #cda45e;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
#menu{
  width: 100vw;

}

.menu #menu-flters {
  padding: 0;
  margin: 0 auto 0 auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.menu #menu-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 0px 4px 0px 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  margin-bottom: 10px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  font-family:  "Poppins", sans-serif;
  
}

.direccion{
  display: grid;
  justify-items: center;
}

.direccion li {
  cursor: pointer;
  display: inline-block;
  padding: 0px 4px 0px 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  margin: 10px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  font-family:  "Poppins", sans-serif;
  
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
  color: #cda45e;
}

.menu #menu-flters li:last-child {
  margin-right: 0;
}

.menu .menu-item {
  margin-top: 35px;
}

.menu .menu-img {
  margin-top: -9px;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  float: left;
  border: 5px solid rgba(255, 255, 255, 0.2);
  margin-right: 4px;
  cursor: pointer;
  z-index: 99;
}

.menu .menu-content {
  margin-left: 80px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.gslide-image img {
  max-height: 60vh !important;
  max-width: 100% !important;
}

.gslide-media {
  display: flex;
  width: auto;
  justify-content: center;
}

.menu .menu-content::after {
  content: "............................................""........................................................................";
  position: absolute;
  left: 20px;
  right: 0;
  top: -4px;
  z-index: 1;
  color: #bab3a6;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.menu .menu-content a {
  padding-right: 10px;
  background: #1a1814;
  position: relative;
  z-index: 3;
  font-weight: 600;
  color: #cda45e ;
  transition: 0.3s;
}

.menu .menu-content a:hover {
  color: #cda45e;
}

.menu .menu-content span {
  background: #1a1814;
  position: relative;
  z-index: 3;
  font-weight: 700 !important;
  color: #cda45e;
  left: -12px;
  top: -1px;
  text-align: center;
}

.menu .menu-ingredients {
  display: flex;
  justify-content: space-between;
  margin-left: 80px;
  font-style: italic;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color:  var(--letter-gris);
}

.categoriaMenu{
  letter-spacing: 2px;
  text-transform: uppercase;
  left: 87px;
  top: -19px;
  position: absolute;
  color: var(--primaryMenu) !important;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

/*
.categoriaMenu::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 4px 10px;
}*/

/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/



.specials .nav-tabs {
  border: 0;
}

.specials .nav-link {
  border: 0;
  padding: 12px 15px;
  transition: 0.3s;
  color: #fff;
  border-radius: 0;
  border-right: 2px solid #cda45e;
  font-weight: 600;
  font-size: 15px;
}

.specials .nav-link:hover {
  color: #cda45e;
}

.specials .nav-link.active {
  color: #1a1814 !important;
  background: #cda45e !important;
  border-color: #cda45e !important;
}

.specials .nav-link:hover {
  border-color: #cda45e;
}

.specials .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff !important;
}

.specials .details p {
  color: #aaaaaa;
}

.specials .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .specials .nav-link {
    border: 0;
    padding: 15px;
  }
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/
.events {
  background: url("https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80") center center no-repeat;
  background-size: cover;
  position: relative;
}

.events::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.events .section-title h2 {
  color: #fff;
}

.events .container {
  position: relative;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  
}

.video-background .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 /0.4);
  z-index: 2;
}

@media (min-width: 1024px) {
  .events {
    background-attachment: fixed;
  }
  .wid50{
    width: 50vw !important;
  }
}

.events .events-carousel {
  background: rgba(255, 255, 255, 0.08);
  padding: 30px;
}

.events .event-item {
  color: #fff;
}

.events .event-item h3 {
  font-weight: 600;
  font-size: 26px;
  color: #cda45e;
}
.priceTotal{
  color: var(--primary);
  font-weight: 700;
  font-size: 218x;
}

.events .event-item .price {
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.events .event-item .price span {
  border-bottom: 2px solid #cda45e;
}

.events .event-item ul {
  list-style: none;
  padding: 0;
}

.events .event-item ul li {
  padding-bottom: 10px;
}

.events .event-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cda45e;
}

.events .event-item p:last-child {
  margin-bottom: 0;
}

.events .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #cda45e;
}

/*--------------------------------------------------------------
# Book A Table
--------------------------------------------------------------*/
.book-a-table .php-email-form {
  width: 100%;
}

.book-a-table .php-email-form .form-group {
  padding-bottom: 8px;
}

.book-a-table .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.book-a-table .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.book-a-table .php-email-form .error-message br+br {
  margin-top: 25px;
}

.book-a-table .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.loading {
  display: none;
  text-align: center;
  padding: 15px;
  padding: 0px 20px 2px 20px !important;
}

.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* margin: 0 10px -6px 0; */
  border: 3px solid #cda45e;
  border-top-color: #1a1814;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.book-a-table .php-email-form input,
.book-a-table .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: #0c0b09;
  border-color: #625b4b;
  color: white;
}

.book-a-table .php-email-form input::-moz-placeholder,
.book-a-table .php-email-form textarea::-moz-placeholder {
  color: #a49b89;
}

.book-a-table .php-email-form input::placeholder,
.book-a-table .php-email-form textarea::placeholder {
  color: #a49b89;
}

.book-a-table .php-email-form input:focus,
.book-a-table .php-email-form textarea:focus {
  border-color: #cda45e;
}

.book-a-table .php-email-form input {
  height: 44px;
}

.book-a-table .php-email-form textarea {
  padding: 10px 12px;
}

.book-a-table .php-email-form button[type=submit] {
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.book-a-table .php-email-form button[type=submit]:hover {
  background: #d3af71;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/


.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid rgba(255, 255, 255, 0.2);
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: white;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #d3af71;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: #26231d;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.testimonials .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #cda45e;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  border-right: 3px solid #454035;
  border-bottom: 3px solid #454035;
}

.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Chefs
--------------------------------------------------------------*/
.chefs .member {
  text-align: center;
  margin-bottom: 20px;
  background: #343a40;
  position: relative;
}

.chefs .member .member-info {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

.chefs .member .member-info-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  transition: bottom 0.4s;
}

.chefs .member .member-info-content h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

.chefs .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

.chefs .member .social {
  position: absolute;
  left: 0;
  bottom: -38px;
  right: 0;
  height: 48px;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
}

.chefs .member .social a {
  transition: color 0.3s;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
}

.chefs .member .social a:hover {
  color: #cda45e;
}

.chefs .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.chefs .member:hover .member-info {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
  opacity: 1;
  transition: 0.4s;
}

.chefs .member:hover .member-info-content {
  bottom: 60px;
  transition: bottom 0.4s;
}

.chefs .member:hover .social {
  bottom: 0;
  transition: bottom ease-in-out 0.4s;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  float: left;
  width: 44px;
  height: 44px;
  background: #cda45e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #bab3a6;
}

.contact .info .open-hours,
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.btnCircular{
  margin: 5px;
  font-size: 20px;
  float: left;
  width: 36px;
  height: 33px;
  background: #cda45e;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  border-style: hidden;
  color: var(--white);
}

.loading {
  display: none;
  text-align: center !important;
  padding: 15px;
}

.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #cda45e;
  border-top-color: #1a1814;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: #0c0b09;
  border-color: #625b4b;
  color: white;
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
  color: #a49b89;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
  color: #a49b89;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #cda45e;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #d3af71;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footerMenu {
  margin-bottom: -5px;
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;

}

#footerMenu .footer-top {
  background: #0c0b09;
  border-top: 1px solid #37332a;
  border-bottom: 1px solid #28251f;
  padding: 60px 0 30px 0;
}

#footerMenu .footer-top .footer-info {
  margin-bottom: 30px;
}

#footerMenu .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

#footerMenu .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #fff;
}

#footerMenu .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #28251f;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footerMenu .footer-top .social-links a:hover {
  background: #cda45e;
  color: #fff;
  text-decoration: none;
}

#footerMenu .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footerMenu .footer-top .footer-links {
  margin-bottom: 30px;
}

#footerMenu .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footerMenu .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #cda45e;
  font-size: 18px;
  line-height: 1;
}

#footerMenu .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footerMenu .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footerMenu .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footerLink{
  color: #cda45e;
}

#footerMenu .footer-top .footer-links ul a:hover {
  color: #cda45e;
}

#footerMenu .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #28251f;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  border: 1px solid #454035;
}

#footerMenu .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
  background: #28251f;
  color: white;
}


#footerMenu .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #cda45e;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footerMenu .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #d3af71;
}

.subcribite{
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #cda45e;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  width: 140px;
  cursor: pointer;
  text-align: center;
}

.subcribite {
  background: #d3af71;
}

button.subscri{
  width: 80% !important;
  cursor: pointer;
}

#footerMenu .copyright {
  text-align: center;
}

#footerMenu .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
  font-style: italic;
}

#inf{
  background: #27272cb9;
  height: 75px;
  border-top: 1px solid #e9e9e9;
  bottom: 0;
  padding: 5px;
  position: fixed;
  width: 100%;
  z-index: 4;
}

.total{
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 35px;
  width: 100%;
  height: 40px;
  line-height: 1.75;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: space-between;
}

/***** Carrito Detalle ****************/
#mobile {
  display: none;
  align-items: center;
}

#close {
  display: none;
}

/* Shoping */

#closeCar {
  display: initial;
  top: 30px;
  left: 30px;
  color: var(--BLACK);
  font-size: 24px;
  cursor: pointer;
}

#carrito.active {
  right: 0px;
}

#cart img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  float: left;
  border: 5px solid rgba(255, 255, 255, 0.2);
}


#carrito {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  height: 100%;
  width: 112vw;
  right: -2000px;
  background-color: #28251f;
  box-shadow: 0 40px 60px rgb(0 0 0 / 2%);
  padding: 35px 0 0 0;
  z-index: 999999 !important;
  transition: all 0.5s ease-in-out;
  overflow: visible;
  padding: 14px 30px;
}


.cabezal {
  display: flex;
  align-items: center;
  padding:10px;
  background: var(--primaryMenu);
  margin-bottom: 5px;
  width: 110%;
  text-decoration: none;
  justify-content: space-around;
  font-family: "Poppins", sans-serif !important;
  align-items: baseline;
}

.cabezal #title {
  text-decoration: none;
}



button.end {
  padding: 10px 20px;
  width: 90%;
}

.cantCard{
  
  display: flex;
  flex-direction: row-reverse;
}
.quatity{
  margin-top: -12px;
  margin-right: -5px;
  color: var(--news);
}


.end h4 {
  font-size: 18px;
}

.end h3 {
  font-size: 18px;
}

button.end.second:hover {
  color: var(--white) !important;
  background: var(--yellow) !important;
  transition: 3ms;
}

#comprar .duo {
  display: block;
  width: 100%;
  top: 40px;
}

.photo {
  padding: 5px 5px;
}

#cart.confirm {
  width: 100%;
  text-align: center;
  display: flex;
  border-bottom: 1px solid var(--border);
  border-width: 80%;
  margin: 0px 18px;
}

.detalles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 5px 20px;
  text-align: left;
  gap: 7px;
  width: 90%;
}

.detalles.name {
  margin-top: 8px;
}

.detalles h6 {
  font-size: 18px !important;
  color: var(--primaryMenu);
  font-weight: 600 !important;
}

.detalles.interno {
  justify-content: space-between;
}

.qty{
  margin-left: 4px;
}

.detalles input {
  width: 42px;
  padding: 5px 5px 5px 10px;
  border: 1px solid var(--border);
  border-radius: 4px;
}

.detalles input:hover {
  border: 1px solid var(--yellow);
}

.boxes {
  display: flex;
  flex-direction: column;
  width: 90%;
}

#context {
  display: contents;
}

.qty {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  margin-top:-10px;
}

.qty .material-symbols-outlined{
  color: #e9e9e9;
  cursor: pointer;
  
}
#cantidad {
  font-size: 18px;
  color: #eee;
  
}

button.normal {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  padding: 15px 30px;
  color: #fff;
  background-color:  #cda45e;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  transition: 0.4s;
}

.delete{
  color: #eee;
  cursor: pointer;
}

.end {
  background-color: #cda45e !important; 
  border: 0;
  color: #fff !important;
  margin: 3px 15px !important;
  font-size: 16px;
  padding: 7px 20px 2px 20px !important;
  transition: 0.3s;
  border-radius: 50px !important;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.2);
}

button#add {
  border-radius: 16px;
}

button.normal {
  font-size: 16px;
  padding: 10px 20px;
}

#comprar .duo {
  display: grid;
  width: 60%;
  top: 40px;
}

#comprar {
  display: flex;
  padding: 5px;
  width: 140%;
  bottom: 96px;
  position: absolute;
  padding: 0 0 0px 8px !important;
}

#pedido{
  overflow-x: hidden;
  overflow-y: scroll;
  height: 65%;
}

.topbot{
  margin: 10px 0;
}

#subtotal {
  display: grid;
  justify-content: space-between;
  padding: 12px 15px !important;
  align-items: flex-end;
  color: #fff;
  letter-spacing: 1.5px;
  border-style: outset;
  border-radius: 25px;
  border-style: outset; 
  border-width: 2px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px !important;
  align-items: flex-end;
  color: #fff;
  font-weight: 550;
  letter-spacing: 4px;
}

.detalles .precio{
  font-size: 19px !important;
  font-weight: 550;
}

.pedido{
  letter-spacing: 3px;
  padding: 10px 0px !important;
}

#navbar {
  display: flex;

}
/* .oculta {
  display: none !important;
  transition: all .3s ease;
  visibility: hidden;
  opacity: 0;
}

.opacar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

}

.visible {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  margin-left: 0px !important;
  z-index: 99;
  transition: all 0.3s ease-in-out;
} */

.alert{
  text-align: center;

  width: 100%;
}

.alert h6{
    font-weight: 550 !important;
    letter-spacing: 1px;
  }

.center{
  display: grid;
    justify-content: center;
    justify-items: center;
}

.textCenter{
  text-align: center !important;
}

#subtotal td, th {
  padding: 8px; /* Agrega 10px de espaciado entre el borde y el contenido de cada celda */
}

#subtotal h8{
  font-size: 15px;
  
}


.bi-info-circle{
  position: fixed;
  font-size: 11px;
}

.modal{

  visibility: visible;
    background-color: rgba(0, 0, 0, 0.3)
}

.modal-dialog {
  top: 35px;

}


.modal-contentMenu {
  background: #cda45e !important;

}

.btn-close{
  margin-left: 5px !important;
  cursor: pointer;
  font-size: 22px;
}

.modal-header {
  border-bottom: #1a1814 !important;
}
.modal-bodyMenu {
  border-radius: 0 0 8px 8px;
  background: #1a1814;
  border-top: solid #1a1814;
}
.modal-footerMenu{
  border-top: solid #1a1814 !important;
  background: #1a1814 !important;
  justify-content: center;
  align-items: center;
}

.my-3{
  gap: 10px;
  display: flex;
  align-items: flex-start;

}

.my-3 h6{
   font-size: 14px;
}
.modal-footer button .edd{

  background: var(--yellow);
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    font-weight: 600;

    width: 65% !important;
}

.form-control{
  font-family: 'Quicksand', sans-serif;
}


.d-block {
  display: flex!important;
  align-items: center;
}

.yellowColor{
  font-size: 32px !important;
  color: var(--yellow) !important;
}

.yellowLetter{
  color: var(--yellow) !important;
}

.bi-check-circle-fill{
  font-size: 32px !important;
  color: var(--yellow)
}

.accordion{
  border-radius: 15px !important;
  gap: 5px;
  display: grid;
}

 .accordion-item {
  border-radius: 15px !important;
}

.accordion-header{
  border-radius: 15px !important;
}

.accordion-button{
  border-radius: 15px !important;
}

.totales tr .precios{
  text-align: end !important;
}

.flex{
  display: flex;
  justify-content: space-between;
  max-width: 85vw;
}

.imgModal{
  max-width: 85vw !important;
  border-radius: 38px;
  margin: 4px !important;
}

.colorprimaryMenu{
  color: var(--primaryMenu) !important;
}

.pad10{
  background-color: var(--marron) !important;
  color: white !important;
  padding: 10px !important;
}

.martop4{
  margin-top: 4px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footerlogo{
  height: 75px;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-top: 13px;
}

.mapMenu{
border:0; 
width: 100%;
height: 300px;
}


.buttonMenu{
  background: rgb(205, 164, 94);
    border: 0px;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    transition: all 0.4s ease 0s;
    border-radius: 50px;
    margin: 6px;
    font-size: 14px;
    text-transform: uppercase;
    height: 100%;
}

.cupon{
  width: 60%;
  border-radius: 8px;
  padding-left: 8px;
  border-style: hidden;
}

.copon {
  position: relative;
  box-sizing: border-box;
  width: fit-content;
}

.mainbox {
  box-sizing: border-box;
  position: relative;
  width: 230px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 160px;
  background-color: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.checkbox:focus {
  border: none;
  outline: none;
}

.checkbox:checked {
  right: 10px;
}

.checkbox:checked ~ .mainbox {
  width: 50px;
}

.checkbox:checked ~ .mainbox .search_input {
  width: 0;
  height: 0px;
}

.checkbox:checked ~ .mainbox .iconContainer {
  padding-right: 8px;
}

.checkbox {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 9;
  cursor: pointer;
  appearance: none;
}

.search_input {
  box-sizing: border-box;
  height: 100%;
  width: 170px;
  background-color: transparent;
  border: none;
  outline: none;
  padding-bottom: 4px;
  padding-left: 10px;
  font-size: 1em;
  color: white;
  transition: all 0.3s ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.search_input::placeholder {
  color: rgba(255, 255, 255, 0.776);
}

.iconContainer {
  box-sizing: border-box;
  padding-top: 5px;
  width: fit-content;
  transition: all 0.3s ease;
}

.search_icon {
  box-sizing: border-box;
  fill: white;
  font-size: 1.3em;
}

/*-----------------------Errors--------------------------------*/


.moon {
  background: linear-gradient(90deg, rgba(208,208,208,1) 48%, rgba(145,145,145,1) 100%);

  top: -100px;
  left: -300px;
  width: 300px;
  height: 300px;
  content: '';
  border-radius: 100%;
  box-shadow: 0px 0px 30px -4px rgba(0,0,0,0.5);
}

.moon__crater {
  position: relative ;
  content: '';
  border-radius: 100%;
  background: linear-gradient(90deg, rgba(122,122,122,1) 38%, rgba(195,195,195,1) 100%);
  opacity: 0.6;
}

.moon__crater1 {
  top: -200px;
  left: 250px;
  width: 20px;
  height: 90px;
}

.moon__crater2 {
  top: -150px;
  left: 200px;
  width: 10px;
  height: 20px;
  transform: rotate(55deg);
}

.moon__crater3 {
  top: -290px;
  left: 90px;
  width: 15px;
  height: 30px;
  transform: rotate(250deg);
}

.star {
  background: #f1f1f1;
  position: absolute;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 100%;
  transform: rotate(250deg);
  opacity: 0.4;
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes shimmer {
  from {opacity: 0;}
  to {opacity: 0.7;}
}

.star1 {
  top: 40%;
  left: 70%;
  animation-delay: 1s;
}

.star2 {
  top: 60%;
  left: 20%;
  animation-delay: 3s;
}

.star3 {
  top: 30%;
  left: 80%;
  animation-delay: 2s;
}

.star4 {
  top: 85%;
  left: 50%;
}

.star5 {
  top: 2%;
  left: 3%;
  animation-delay: 0.5s;
}

.errorMenu {
  position: absolute;
  left: 35px;
  top: 295px;
  transform: translateY(-60%);


}

.error__title {
  font-size: 7.5em;  
  color: #363e49;
}

.error__subtitle {
  font-size: 1.5em;
}

.error__description {
  opacity: 0.5;
}

.error__button {
  min-width: 7em;
  margin-top: 3em;
  margin-right: 0.5em;
  padding: 0.5em 2em;
  outline: none;
  border: 2px solid #2f3640;
  background-color: transparent;
  border-radius: 8em;
  color: #576375;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 0.75em;
  font-weight: 700;
}

.error__button:hover {
  color: #21252c;
}

.error__button--active {
  color: #0c0b09;
}

.error__button--active:hover {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
  color: white;
}

.astronaut {
  position: relative;
  width: 155px;
  height: 300px;
  left: 100px;
  top: 100px;
  transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
}

.astronaut__head {
  background-color: white;
  position: absolute;
  top: 60px;
  left: 60px;
  width: 60px;
  height: 60px;
  content: '';
  border-radius: 2em;
}

#visor{
  background-color: #2f3640;
  border-radius:   60px;
  margin: 1.5px;
  width: 50px;
}
@keyframes hose-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.hose {
  animation: hose-animation 2s infinite;
  stroke: #fff;
}

.astronaut__head-visor-flare1 {
  background-color: #7f8fa6;
  position: absolute;
  top: 28px;
  left: 40px;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 2em;
  opacity: 0.5;
}

.astronaut__head-visor-flare2 {
  background-color: #718093;
  position: absolute;
  top: 40px;
  left: 38px;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 2em;
  opacity: 0.3;
}

.astronaut__backpack {
  background-color: #bfbfbf;
  position: absolute;
  top: 90px;
  left: 47px;
  width: 86px;
  height: 90px;
  content: '';
  border-radius: 8px;
}

.astronaut__body {
  background-color: #e6e6e6;
  position: absolute;
  top: 115px;
  left: 55px;
  width: 70px;
  height: 80px;
  content: '';
  border-radius: 8px;
}

.astronaut__body__chest {
  background-color: #d9d9d9;
  position: absolute;
  top: 140px;
  left: 68px;
  width: 45px;
  height: 25px;
  content: '';
  border-radius: 6px;
}

.astronaut__arm-left1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 127px;
  left: 9px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-30deg);
}

.astronaut__arm-left2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 102px;
  left: 7px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-12deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-right1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 113px;
  left: 100px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
}

.astronaut__arm-right2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 78px;
  left: 141px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-thumb-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 110px;
  left: 21px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(-35deg);
}

.astronaut__arm-thumb-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 90px;
  left: 133px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(20deg);
}

.astronaut__wrist-left {
  background-color: #e67e22;
  position: absolute;
  top: 122px;
  left: 6.5px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-15deg);
}

.astronaut__wrist-right {
  background-color: #e67e22;
  position: absolute;
  top: 98px;
  left: 141px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-10deg);
}

.astronaut__leg-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 50px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(10deg);
}

.astronaut__leg-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 108px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(-10deg);
}

.astronaut__foot-left {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 43px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}

.astronaut__foot-right {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 111px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(-10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}

.astronaut__cord{

  position: relative;
  left: 100px;
  top: 90px;
}



.stats-counter {
  background-attachment: fixed;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1485182708500-e8f1f318ba72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2110&q=80") center -220px;
    background-size: cover;
    padding: 100px 0;
}

#stats-counter .stats-item span {
  font-size: 48px;
  display: block;
  color: #fff;
  font-weight: 700;
}

#stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.6);
}

#stats-counter .stats-item {
  padding: 30px;
  width: 100%;
}


.stats {
  position: relative;
  padding: 90px 0;
  
}


.stats:before {
  content: "";
  background: 
color-mix(in srgb,#1f1f24, transparent 40%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.stats img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.stats .container {
  position: relative !important;
  z-index: 3;
}

.stats .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--default-color);
  font-weight: 700 !important;
}

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}




.swiper-slide{
  width: 75vw !important;
  margin-right: 20px;
  height: 100%;
    position: relative;
    transition-property: transform;
}

.swiper-wrapper {
  position: relative;
  width: 50vw !important ;
  height: 90vh !important;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper {
  width: 80vw !important ;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden; 
  list-style: none;
  padding: 10px !important;
  z-index: 1;
}

html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.metopago{

  background: var(--dark-grey) !important;
  border-radius: 24px !important;
  border-color: var(--dark-grey) !important;
}

.primaryMenu{
    color: var(--primary) !important;
    font-weight: 650 !important;
    letter-spacing: 5px !important;
   font-family: "Quicksand", sans-serif;
    text-transform: uppercase;
    margin-left: 12px;  
}

.text-quicksand{
  font-family: "Quicksand", sans-serif !important;
}

.decimal{
  line-height: 200%;
  margin-left: -30px;
}

.grid{
  display: grid;
  justify-items: center;
  align-items: center;
  align-content: space-evenly;
  gap: 8px;
}
.spinner {
  width: 88px;
  height: 88px;
  animation: spinner-c601d3 1s infinite;
  background-color: #41A58D;
  border-radius: 50%;
}

@keyframes spinner-c601d3 {
  0% {
     opacity: 0;
     transform: scale(0) translateX(-300%);
  }

  50% {
     opacity: 1;
     transform: scale(1.25) translateX(0);
  }

  100% {
     opacity: 0;
     transform: scale(0) translateX(300%);
  }
}



/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #1a1814;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1a1814;
  border-top-color: #cda45e;
  border-bottom-color: #cda45e;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 15px;
  bottom: 70px;
  z-index: 996;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
  border: 2px solid #cda45e;
  background-color: var(--dark-grey);
}

.back-to-top i {
  font-size: 28px;
  color: #cda45e;
  line-height: 0;
}

.back-to-top:hover {
  background: #cda45e;
  color: #1a1814;
}

.back-to-top:hover i {
  color: #444444;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .swiper{
    top: 150px;
  }
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  #navbar.active {
    right: 0px;
}

#navbar {
  display: flex !important;
  padding: 80px 0 0 10px;
  transition: 0.3s;
  top: 63px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 63px;
  height: 100vh;
  width: 300px;
  right: -300px;
  background-color: #28251f;
  box-shadow: 0 40px 60px rgb(0 0 0 / 2%);
  z-index: 999999 !important;
  transition: all 0.3s ease-in-out;
  overflow: visible;
}
#navbar li {
  margin-bottom: 25px;
}
#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#close {
  display: initial !important;
  position: absolute;
  top: 30px;
  left: 30px;
  color: #fff;
  font-size: 24px;
}
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info i {
  font-style: normal;
  color: #d9ba85;
}

#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

#topbar .languages ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #cda45e;
}

#topbar .languages ul a {
  color: white;
}

#topbar .languages ul li+li {
  padding-left: 10px;
}

#topbar .languages ul li+li::before {
  display: inline-block;
  padding-right: 10px;
  color:  var(--letter-gris);
  content: "/";
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: rgba(12, 11, 9, 0.6);
  border-bottom: 1px solid rgba(12, 11, 9, 0.6);
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  top: 40px;
}

#header.header-scrolled {
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #37332a;
}

#header .logoo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

#header .logoo a {
  color: #fff;
}

#header .logoo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Book a table button Menu
--------------------------------------------------------------*/
.book-a-table-btn {
  margin: 0 0 0 15px;
  border: 2px solid #cda45e;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
}

.book-a-table-btn:hover {
  background: #cda45e;
  color: #fff;
}

@media (max-width: 992px) {
  .book-a-table-btn {
    margin: 0 15px 0 0;
    padding: 8px 20px;
  }
}


@media (min-width: 550px) {

    
.swiper-slide{
  top: 80.667px !important;
}
  #cart.confirm {
    margin: 0 250px !important;
  }

  .boxes {
    width: 50% !important;
  }

.end{
  width: 50%;
}

#comprar {
  margin: 0 5% !important;
}
.subtotal {
  margin-left: 15%;
}
.subtotal {
  padding: auto !important;
}

#pedido {
 /* width: 70% !important;*/
  overflow-y: auto !important;
}
#carrito{
  align-items: center !important;
 
}

#add {
  margin-right: 8px;

}
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #d9ba85;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #444444;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #cda45e;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
 
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #1a1814;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #cda45e;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #cda45e;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  /*background: url("../img/hero-bg.jpg") top center;*/
  background-size: cover;
  position: relative;
  padding: 0;
  background-size: cover !important;
}



#hero .container {
  padding-top: 110px;
}

  /* @media (max-width: 992px) {
  #hero .container {
 padding-top: 0px;
  }
}*/

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#hero h1 span {
  color: #cda45e;
}

#hero h2 {
  color: #eee;
  margin-bottom: 10px 0 0 0;
  font-size: 28px;
  font-family: "Open Sans", sans-serif;
}

#hero .btns {
  margin-top: 15px;
}

#hero .btn-menu,
#hero .btn-book {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.3s;
  line-height: 1;
  color: white;
  border: 2px solid #cda45e;
}

#hero .btn-menu:hover,
#hero .btn-book:hover {
  background: #cda45e;
  color: #fff;
}

#hero .btn-book {
  margin-left: 15px;
}

#hero .play-btn-sucrip {

  background: radial-gradient(#cda45e 0%, rgba(205, 164, 94, 0.4) 84%);
  border-radius: 50px;
  position: relative;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 750;
  padding-top: 8px;

}

#hero .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn-sucrip::before {
margin-left: 40px;
content: "";
position: absolute ;
  width: 190px;
  height: 45px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 0,5;
  border-radius: 50%;
  border: 5px double rgba(205, 164, 94, 0.4);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#hero .play-btn:hover::after {
  border-left: 15px solid #cda45e;
  transform: scale(20);
}

#hero .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

#add{
  margin-right: 20px;
  cursor: pointer;
  font-size: 30px;
  color: #eee;
 
 
}

#add:hover{
  color: #cda45e;
  transform: translateY(-1px);
  /*box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);*/
}


.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  #hero .play-btn {
    margin-top: 30px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: auto;
  }

  #hero .container {
    padding-top: 130px;
    padding-bottom: 60px;
  }

}

@media (max-width: 500px) {
  .menu .menu-content::after {
    content: "................................................." !important;
  }
  .menu-content span{
    width: 60px;
  }
  #add{
    margin-right: 8px;

  }

  #carrito {
  padding: 0px;

  }

  #comprar{
    margin-left: -14px;

  }
}


@media (max-width: 400px) {
  #comprar {
    margin-left:0px !important;
  }

  #comprar .subtotal {
   padding:10px 25px !important; 
  }
  #carrito #comprar .duo {
   margin: 0; 
  }
  .menu .menu-content::after {
    content: ".................................." !important;
  }
  #pedido {
    margin-left: -45px;
    width: auto !important;
}
.boxes {

  width: 70% !important;
}

#cart.confirm {
  margin: 0px 40px !important;
}
}

@media (max-width: 340px){
#cart.confirm {
    margin: 0px 8% !important;
}
}

@media (max-width: 768px) {

  .sucrip{
    display: grid !important;   
    justify-items: center;
    gap: 25px;
   
  }

  #hero{
    margin-top: 95px;
  }

  #hero h1 {
    margin-top: 25px;
    font-size: 28px;
    line-height: 36px;
    width: 80vw !important;
    z-index: 99999;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Sections General.section {
  padding: 100px 0;

}
--------------------------------------------------------------*/


.section-bg {
  background-color: #1a1814;
}

.section-title {
  padding-top: 15px !important; 
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 4px 10px;
}

.section-title p {
  margin: 0 2px;
  font-size: 36px;
  font-weight: 700;
  font-family:  "Poppins", serif;
  color: #cda45e;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #1d1b16;
  margin-top: 110px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 98px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #37332a;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }

}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  /* background: url("../img/about-bg.jpg") center center; */
  background-size: cover;
  position: relative;
  padding: 30px 0;
}

.about:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .about-img {
  position: relative;
  transition: 0.5s;
}

.about .about-img img {
  max-width: 100%;
  border: 4px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.about .about-img::before {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
  height: 60px;
  z-index: 1;
  content: "";
  border-left: 5px solid #cda45e;
  border-top: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img::after {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  z-index: 2;
  content: "";
  border-right: 5px solid #cda45e;
  border-bottom: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img:hover {
  transform: scale(1.03);
}

.about .about-img:hover::before {
  left: 10px;
  top: 10px;
}

.about .about-img:hover::after {
  right: 10px;
  bottom: 10px;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
  text-align: justify;
  color: var(--gris);
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cda45e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .about {
    background-attachment: fixed;
  }

  #comprar{
    width: 100% !important;
   
  }
  

.subtotal {
  padding: auto !important;
  margin-left: 6% !important;
}

  button.end {
    width: 25% !important;
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  background: #1a1814;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #cda45e;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.8);
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.why-us .box:hover {
  background: #cda45e;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover path,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
  fill: #fff;
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
.menu #menu-flters {
  padding: 0;
  margin: 0 auto 0 auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.menu #menu-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 0px 4px 0px 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  margin-bottom: 10px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  font-family: "Open Sans", sans-serif;
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
  color: #cda45e;
}

.menu #menu-flters li:last-child {
  margin-right: 0;
}

.menu .menu-item {
  margin-top: 50px;
}

.menu .menu-img {
  width: 70px;
  border-radius: 50%;
  float: left;
  border: 5px solid rgba(255, 255, 255, 0.2);
}

.menu .menu-content {
  margin-left: 85px;

  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu .menu-content::after {
  content: "...................................""...............................................................";
  position: absolute;
  left: 20px;
  right: 0;
  top: -4px;
  z-index: 1;
  color: #bab3a6;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.menu .menu-content a {
  padding-right: 10px;
  background: #1a1814;
  position: relative;
  z-index: 3;
  font-weight: 700;
  color: white;
  transition: 0.3s;
}

.menu .menu-content a:hover {
  color: #cda45e;
}

.menu .menu-content span {
  background: #1a1814;
  position: relative;
  z-index: 3;
  font-weight: 700;
  color: #cda45e;
  left: -2px;
  top: -2px;
}

.menu .menu-ingredients {
  display: flex;
  justify-content: space-between;
  margin-left: 85px;
  font-style: italic;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color:  var(--letter-gris);
}

/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/



.specials .nav-tabs {
  border: 0;
}

.specials .nav-link {
  border: 0;
  padding: 12px 15px;
  transition: 0.3s;
  color: #fff;
  border-radius: 0;
  border-right: 2px solid #cda45e;
  font-weight: 600;
  font-size: 15px;
}

.specials .nav-link:hover {
  color: #cda45e;
}

.specials .nav-link.active {
  color: #1a1814;
  background: #cda45e;
  border-color: #cda45e;
}

.specials .nav-link:hover {
  border-color: #cda45e;
}

.specials .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}

.specials .details p {
  color: #aaaaaa;
}

.specials .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .specials .nav-link {
    border: 0;
    padding: 15px;
  }
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/


.events .section-title h2 {
  color: #fff;
}

.events .container {
  position: relative;
}

@media (min-width: 1024px) {
  .events {
    background-attachment: fixed;
  }
}

.events .events-carousel {
  background: rgba(255, 255, 255, 0.08);
  padding: 30px;
}

.events .event-item {
  color: #fff;
}

.events .event-item h3 {
  font-weight: 600;
  font-size: 26px;
  color: #cda45e;
}

.events .event-item .price {
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.events .event-item .price span {
  border-bottom: 2px solid #cda45e;
}

.events .event-item ul {
  list-style: none;
  padding: 0;
}

.events .event-item ul li {
  padding-bottom: 10px;
}

.events .event-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cda45e;
}

.events .event-item p:last-child {
  margin-bottom: 0;
}

.events .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #cda45e;
}

/*--------------------------------------------------------------
# Book A Table
--------------------------------------------------------------*/
.book-a-table .php-email-form {
  width: 100%;
}

.book-a-table .php-email-form .form-group {
  padding-bottom: 8px;
}

.book-a-table .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.book-a-table .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.book-a-table .php-email-form .error-message br+br {
  margin-top: 25px;
}

.book-a-table .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.loading {
  display: none;
  text-align: center;
  padding: 15px;
  padding: 0px 20px 2px 20px !important;
}

.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* margin: 0 10px -6px 0; */
  border: 3px solid #cda45e;
  border-top-color: #1a1814;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.book-a-table .php-email-form input,
.book-a-table .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: #0c0b09;
  border-color: #625b4b;
  color: white;
}

.book-a-table .php-email-form input::-moz-placeholder,
.book-a-table .php-email-form textarea::-moz-placeholder {
  color: #a49b89;
}

.book-a-table .php-email-form input::placeholder,
.book-a-table .php-email-form textarea::placeholder {
  color: #a49b89;
}

.book-a-table .php-email-form input:focus,
.book-a-table .php-email-form textarea:focus {
  border-color: #cda45e;
}

.book-a-table .php-email-form input {
  height: 44px;
}

.book-a-table .php-email-form textarea {
  padding: 10px 12px;
}

.book-a-table .php-email-form button[type=submit] {
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.book-a-table .php-email-form button[type=submit]:hover {
  background: #d3af71;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/


.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid rgba(255, 255, 255, 0.2);
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: white;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #d3af71;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: #26231d;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.testimonials .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #cda45e;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  border-right: 3px solid #454035;
  border-bottom: 3px solid #454035;
}

.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Chefs
--------------------------------------------------------------*/
.chefs .member {
  text-align: center;
  margin-bottom: 20px;
  background: #343a40;
  position: relative;
}

.chefs .member .member-info {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

.chefs .member .member-info-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  transition: bottom 0.4s;
}

.chefs .member .member-info-content h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

.chefs .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

.chefs .member .social {
  position: absolute;
  left: 0;
  bottom: -38px;
  right: 0;
  height: 48px;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
}

.chefs .member .social a {
  transition: color 0.3s;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
}

.chefs .member .social a:hover {
  color: #cda45e;
}

.chefs .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.chefs .member:hover .member-info {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
  opacity: 1;
  transition: 0.4s;
}

.chefs .member:hover .member-info-content {
  bottom: 60px;
  transition: bottom 0.4s;
}

.chefs .member:hover .social {
  bottom: 0;
  transition: bottom ease-in-out 0.4s;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  float: left;
  width: 44px;
  height: 44px;
  background: #cda45e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #bab3a6;
}

.contact .info .open-hours,
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.loading {
  display: none;
  text-align: center !important;
  padding: 15px;
}

.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #cda45e;
  border-top-color: #1a1814;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: #0c0b09;
  border-color: #625b4b;
  color: white;
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
  color: #a49b89;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
  color: #a49b89;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #cda45e;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #d3af71;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #0c0b09;
  border-top: 1px solid #37332a;
  border-bottom: 1px solid #28251f;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #28251f;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #cda45e;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #cda45e;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #cda45e;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #28251f;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  border: 1px solid #454035;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
  background: #28251f;
  color: white;
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #cda45e;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #d3af71;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#inf{
  background: #27272cb9;
  height: 55px;
  border-top: 1px solid #e9e9e9;
  bottom: 0;
  padding: 5px;
  position: fixed;
  width: 100%;
  z-index: 4;
}

.total{
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 35px;
  width: 100%;
  height: 40px;
  line-height: 1.75;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: space-between;
}

/***** Carrito Detalle ****************/
#mobile {
  display: none;
  align-items: center;
}

#close {
  display: none;
}

/* Shoping */

#closeCar {
  display: initial;
  top: 30px;
  left: 30px;
  color: var(--BLACK);
  font-size: 24px;
  cursor: pointer;
}

#carrito.active {
  right: 0px;
}

#cart img {
  width: 80px;
  border-radius: 50%;
  float: left;
  border: 5px solid rgba(255, 255, 255, 0.2);
}


#carrito {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  height: 100%;
  width: 112vw;
  right: -2000px;
  background-color:  #1a1814;
  box-shadow: 0 40px 60px rgb(0 0 0 / 2%);
  padding: 35px 0 0 0;
  z-index: 999999 !important;
  transition: all 0.5s ease-in-out;
  overflow: visible;
  padding: 30px;
}


.cabezal {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  background: var(--primary);
  margin-bottom: 5px;
  width: 110%;
  text-decoration: none;
  justify-content: space-around;
}

.cabezal #title {
  text-decoration: none;
}



button.end {
  padding: 10px 20px;
  width: 90%;
}

.cantCard{
  
  display: flex;
  flex-direction: row-reverse;
}
.quatity{
  margin-top: -12px;
  margin-right: -5px;
  color: var(--news);
}


.end h4 {
  color: var(--second) !important;
  font-size: 18px;
}

.end h3 {
  font-size: 18px;
}

button.end.second:hover {
  color: var(--white) !important;
  background: var(--yellow) !important;
  transition: 3ms;
}

#comprar .duo {
  display: block;
  width: 100%;
  top: 40px;
}

.photo {
  padding: 5px 5px;
}

#cart.confirm {
  width: 100%;
  text-align: center;
  display: flex;
  border-bottom: 1px solid var(--border);
  border-width: 80%;
  margin: 0px 18px;
}

.detalles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 5px 20px;
  text-align: left;
  gap: 7px;
  width: 90%;
}

.detalles.name {
  margin-top: 8px;
}

.detalles h6 {
  font-size: 18px !important;
  color: #e6e6e6;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

.detalles.interno {
  justify-content: space-between;
}

.qty{
  margin-left: 4px;
}

.detalles input {
  width: 42px;
  padding: 5px 5px 5px 10px;
  border: 1px solid var(--border);
  border-radius: 4px;
}

.detalles input:hover {
  border: 1px solid var(--yellow);
}

.boxes {
  display: flex;
  flex-direction: column;
  width: 90%;
}

#context {
  display: contents;
}

.qty {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  margin-top:-10px;
}

.qty .material-symbols-outlined{
  color: #e9e9e9;
  cursor: pointer;
  
}
#cantidad {
  font-size: 18px;
  color: #eee;
  
}

button.normal {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  padding: 15px 30px;
  color: #fff;
  background-color:  #cda45e;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  transition: 0.4s;
}

.delete{
  color: #eee;
  cursor: pointer;
}

.end {
  background-color: var(--primary)!important; 
  border: 0;
  color: #fff !important;
  margin: 3px 15px !important;
  font-size: 16px;
  padding: 7px 20px 2px 20px !important;
  transition: 0.3s;
  border-radius: 50px !important;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.2);
}

button#add {
  border-radius: 16px;
}

button.normal {
  font-size: 16px;
  padding: 10px 20px;
}

#comprar .duo {
  display: grid;
  width: 60%;
  top: 40px;
}

#comprar {
  display: flex;
  padding: 5px;
  width: 140%;
  bottom: 96px;
  position: absolute;
  padding: 0 0 10px 0px !important;
}

#pedido{
  overflow-x: hidden;
  overflow-y: scroll;
  height: 65%;
}

.topbot{
  margin: 10px 0;
}

#subtotal {
  display: grid;
  justify-content: space-between;
  padding: 12px 15px !important;
  align-items: flex-end;
  color: #fff;
  letter-spacing: 1.5px;
  border-style: outset;
  border-radius: 25px;
  border-style: outset; 
  border-width: 2px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px !important;
  align-items: flex-end;
  color: #fff;
  font-weight: 550;
  letter-spacing: 4px;
}

.detalles .precio{
  font-size: 19px !important;
  font-weight: 550;
}

.pedido{
  letter-spacing: 3px;
  padding: 10px 0px !important;
}

#navbar {
  display: flex;

}
/* .oculta {
  display: none !important;
  transition: all .3s ease;
  visibility: hidden;
  opacity: 0;
}

.opacar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

}

.visible {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  margin-left: 0px !important;
  z-index: 99;
  transition: all 0.3s ease-in-out;
} */

.alert{
  text-align: center;

  width: 100%;
}

.alert h6{
    font-weight: 550 !important;
    letter-spacing: 1px;
  }

.center{
  display: grid;
    justify-content: center;
    justify-items: center;
}

#subtotal td, th {
  padding: 8px; /* Agrega 10px de espaciado entre el borde y el contenido de cada celda */
}

#subtotal h8{
  font-size: 15px;
  
}


.bi-info-circle{
  position: fixed;
  font-size: 11px;
}

.modal{

  visibility: visible;
    background-color: rgba(0, 0, 0, 0.3)
}

.modal-dialog {
  top: 35px;

}


.modal-content {
  background: #cda45e !important;

}

.btn-close{
  margin-left: 5px !important;
}

/* .modal-header {
  border-bottom: inset #1a1814 !important;
} */
.modal-body {
  border-radius: 0 0 8px 8px;
  background: #1a1814;
  border-top: solid #1a1814 ;
}
.modal-footer{
  border-top: solid #1a1814 !important;
  background: #1a1814 !important;
  justify-content: center;
  align-items: center;
}

.my-3{
  gap: 10px;
  display: flex;
  align-items: flex-start;

}

.my-3 h6{
   font-size: 14px;
}
.modal-footer button{

  background: var(--yellow);
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    font-weight: 600;

    width: 65% !important;
}

.form-control{
  font-family: 'Quicksand', sans-serif;
}


.d-block {
  display: flex!important;
  align-items: center;
}

.bi-check-circle-fill{
  font-size: 32px !important;
  color: var(--yellow)
}

.accordion{
  border-radius: 15px !important;
  gap: 5px;
  display: grid;
}

 .accordion-item {
  border-radius: 15px !important;
}

.accordion-header{
  border-radius: 15px !important;
}

.accordion-button{
  border-radius: 15px !important;
}

.totales tr .precios{
  text-align: end !important;
}

.flex{
  display: flex;
  justify-content: space-between;
  max-width: 85vw;
}

.imgModal{
  max-width: 85vw !important;
  border-radius: 38px;
  margin: 4px !important;
}

.colorprimary{
  color: var(--primary) !important;
}

.pad10{
  background-color: var(--marron) !important;
  color: white !important;
  padding: 10px !important;
}

.filter-active {
  color: #cda45e ;
  font-weight: 700 !important;
}

.wr-20{
  right: 20vw !important;
}

.box{
  /*position: absolute;*/
  z-index: 99;
  right: 20vw;
}

.input {
  padding: 18px;
  width: 44px;
  height: 44px;
  background: var(--dark-grey);
  border: 3px solid var(--white) ;
  border-radius: 50px;
  box-sizing: border-box;
  text-indent: 10px;
  color: var(--white);
  outline: none;
  transition: .5s;
}
.box:hover  input{
  width: 300px;
  background: #3b3640;
  border-radius: 40px;
}

.box i{
  position: relative;
  left: -50%;
  font-size: 20px;
  color: var(--white);
  transition: .2s;

}
.box:hover i{
  left: -10%;
}

.box input:focus{
  width: 300px;
  transition:  0.25s ;
}

.box:before, 
.box:after {
	transition:  0.25s ;
}
.box input:focus ~ i,
.box input:not(:placeholder-shown)  ~ i{
  left: -10%;
}

.box input:not(:placeholder-shown) {
  width: 240px;
  transition:  0.25s ;
}

.subtipo:after {
  border-radius: 7px;
  position: absolute;
  content: "";
  height: 2px;
  bottom: 5px;
  background: var(--primary);
  width: 30%;
  left: 15px;
  z-index: 99;
}

.maximo{
  font-style: italic;
}
.maximo:after {
  border-radius: 7px;
  position: absolute;
  content: "";
  height: 2px;
  bottom: 5px;
  background: var(--gris);
  width: calc(80% - 15px);
  right: 40px;
}

.letter-gris{
  color: var(--letter-gris) !important;
}

.wizard-progress {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
}

.wizard-progress .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-size: 16px;
  color: var(--gris); /* Sustituye con el color que quieres para $baseColor */
  font-weight: bold;
}

.wizard-progress .step:not(:last-child)::before {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 32px;
  background-color: var(--gris); /* Sustituye con el color que quieres para $baseColor */
  height: 6px;
  width: 100%;
}

.wizard-progress .step .node {
  display: inline-block;
  border: 7px solid var(--gris); /* Sustituye con el color que quieres para $baseColor */
  background-color: var(--gris); /* Sustituye con el color que quieres para $baseColor */
  border-radius: 18px;
  height: 22px;
  width: 22px;
  position: absolute;
  top: 25px;
  left: 50%;
  margin-left: -18px;
}

.wizard-progress .step.complete::before {
  background-color: var(--primary); /* Sustituye con el color que quieres para $mainColor */
}

.wizard-progress .step.complete .node {
  border-color: var(--primary); /* Sustituye con el color que quieres para $mainColor */
  background-color: var(--primary); /* Sustituye con el color que quieres para $mainColor */
}

.wizard-progress .step.complete .node::before {
  position: absolute;
  left: -2px;
  top: -7px;
  content: "✓";
  color: var(--white);
}

.wizard-progress .step.in-progress::before {
  background: linear-gradient(to right, var(--primary) 0%, var(--gris) 100%);
}

.wizard-progress .step.in-progress .node {
  border-color: var(--primary); /* Sustituye con el color que quieres para $mainColor */
}

.why-us .icon-box {
  text-align: center;
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(55, 55, 63, 0.1);
  transition: 0.3s;
}

.why-us .icon-box i {
  color: var(--gris);

  font-size: 52px;
  margin-bottom: 15px;
  background: color-mix(in srgb, var(--yellow), transparent 80%);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
}

.why-us .icon-box h4{
  text-transform: none;
color: var(--primaryMenu);
font-weight: 600;
}

.eslogan{
  letter-spacing: 1px; 
  color: #41A58D !important; 
  font-family: "Dancing Script", cursive !important;
  font-size: 42px !important;
  weight: 700 !important;
  }

  .video {
    opacity: 0;
    transform: scale(1);
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

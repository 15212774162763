
:root {
  scroll-behavior: smooth;
  --blue: #002856;
  --light-blue: #54C0E8;
  --dark-grey: #585858;
  --sombra: 0 0 13px 0 rgba(185,185,185, .25);
  --gris: #E6E4E1;
  --yellow: #EDCB69;
  --second:#0D824D;
  --primaryAdmi:#41A58D;
  --opaco: #444444;
  --marron: #625b4b;
}

.body{
  background:#f1f1f1 ;
}

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&family=Indie+Flower&family=Merienda:wght@300..900&family=Nothing+You+Could+Do&family=Permanent+Marker&display=swap');

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.indie-flower {
  font-family: 'Permanent Marker', cursive;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}


#bodyAdmi {
  font-family: "Open Sans", sans-serif;
  /* background: #f6f9ff !important; */
  color: var(--opaco);
}

.admi{
  color: #6c757d  !important;
}

.cate{
  color: #444444 !important;
}
.actived {
  color: #cda45e !important;
}

.cate:hover{
  color: #cda45e !important;
}


a {
  color: var(--yellow) ;
  text-decoration: none !important;
  cursor: pointer !important;
}


a:hover {
 
  text-decoration: none !important;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: "Nunito", sans-serif;
} */

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 1399px) {
  #main {
    padding: 20px;
    padding-bottom: 65px
  }

.categorias {
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 15px;

  margin-left: 15px;
}
}
/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #04070645;
  display: flex;
  text-align: start;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-topAdmi {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  background: var(--primaryAdmi);
  width: 40px;
  height: 40px;
  border-radius: 25px;

  transition: all 0.4s;
}

.back-to-topAdmi i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-topAdmi:hover {
  background: var(--second);
  color: #fff;
}

.back-to-topAdmi.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  top: 0;
  left: 0;
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
  width: 90%;
  border-radius: 8px;
  margin-top: 2px;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  width: 90%;
    background-color: #f6f9ff;
    border-radius: 8px;
    margin-top: 6px;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 15% !important;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
  background: #0f0f0e;
  color: #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #625b4b;
  background-color: #E6E4E1;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-button{
  background: #E6E4E1;
  color: #585858;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #625b4b;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #625b4b;
  font-size: 15px;
}

/* Breadcrumbs   font-family: "Nunito", sans-serif;*/
.breadcrumb {
  font-size: 14px;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo img {
  max-height: 42px;
  margin-right: 6px;
}

.logo span {
  font-size: 17px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: var(--yellow);
  letter-spacing: 1px;

}

.barraAdmin{
  font-size: 26px;
  margin-right: 12px;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: var(--primaryAdmi);
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -286px auto auto !important;
} 

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -340px auto auto !important;
} 

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  background: #fff;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 200px;
  z-index: 996;
  transition: all 0.3s;
  padding: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  /* background-color: #2B9D6C ;#2B5F98*/
}



.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 600px) {

  #main.active,
  #footer.active {
    margin-left: 90px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}
.sidebar.active{
    left: -135px;
    display: flex;
    justify-content: flex-end; 
    border-radius: 6px;
 
  }
  
  .sidebar.active .sidebar-nav .nav-link{
    /* width: 6.5rem; */
    justify-content: flex-end;
    display: grid;
  }


  .sidebar.active  .sidebar-nav .nav-link.collapsed i {
    margin-left: 0px !important;
}
 

  .sidebar.active span{
    display: none;
  }
@media (min-width: 600px) {

   #main,
   #footer {
    margin-left: 200px;
  }

 
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: 0.3;
  background: var(--primaryAdmi) ;
  padding: 10px 15px;
  border-radius: 4px;
  gap: 5px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  color: #fff;
}

.sidebar-nav .nav-link.collapsed {
  color: var(--primaryAdmi);
  background: #19437E02 ;
}

.sidebar-nav .nav-link.collapsed i {
  color: var(--primaryAdmi);
}

.sidebar-nav .nav-link:hover {
  color: #f1f1f1;
  background: var(--primaryAdmi);
}

.sidebar-nav .nav-link:hover i {
  color: #f1f1f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
 
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-grey) ;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color:#EDCB69 ;
}

.sidebar-nav .nav-content a.active i {
  background-color: #EDCB69;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #4154f1;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item+.post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #012970;
  padding: 15px 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4154f1;
}

.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #000000;
  background: var(--yellow);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
  border-radius: 16px;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.isLoading::before{
  border: 3px solid #f8f9fa;
  border-top-color: #f6c23e;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

/* .contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
} */

.contact .php-email-form input {
  padding: 10px 15px;

  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
  
  padding: 10px 20px;
  position: relative;
  border-radius: 30px;
}

.contact .php-email-form button[type=submit] {
  /* background: #4154f1; */
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
/* 
.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
} */

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  transition: all 0.3s;

  position: absolute; 
  bottom: 0px;
  width: 100%;
  margin-top: 4px;
}

.footer .copyright {
 
  text-align: center;

}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #139851;
  font-style: italic;
}

/*! =================================================*/
/*! ============= Categorias ============= */
/*! =================================================grid-template-rows: repeat(6, 1fr);*/
.categorias {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	
	font-weight: bold;
	gap: 30px;
	margin-bottom: 40px;

	margin-bottom: 35px !important;

	
}

.categoriaAdmi  {
  display: grid;
	cursor: pointer;
	text-align: center;
	padding: 10px;
	border-radius: 65px;
	background: #fff;
	font-weight: 700;
	color: var(--gris-claro);
	border: 2px solid transparent;
	transition: .3s ease all;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
	font-size: 14px !important;
	width: 135px;
	height: 130px !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tablesmall{
  display: grid;
	cursor: pointer;
	text-align: center;
	padding: 6px;
	border-radius: 65px;
	background: #fff;
	font-weight: 700;
	color:#010101;
	border: 2px solid transparent;
	transition: .3s ease all;
	box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
	font-size: 14px !important;
	width: 40px;
	height: 40px !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.tablesmall:hover, .tablesmall.active{
  background: var(--yellow);
  color: var(--opaco);
}

.num{
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 3px;
  color: #004ed5a7 !important;
}

.num h5{
  font-size: 22px !important;
}

.num h6, span{
  font-weight: 500 !important; 
}

.timer{
  color: #D8BD2D;
  font-size: 16px;
  font-weight: 550;

}

.categoria  h5{
  font-weight: 550;
  font-size: 16px; 
  color: #4e73df;
  display: flex;
  justify-content: flex-end;
}

.categoria h6{
  font-size: 19px;
  font-weight: 600; 
}

.categoria.pagado{
  background: #3dff74d4!important;
}

.pago{
  color: #139851!important; 

  display: flex;
  justify-content: flex-end;
}


#resta{
  color: #ed3c0d;
 }

.categoria:hover {
	box-shadow: var(--sombra);
/*color:var(--blue) !important;*/
box-shadow: 0px 1px 5px 0px #a0a0a0;
}

.categoria :hover path {
	fill: var(--yellow) ;
	
}


.categorias a:hover {
	color:var(--yellow) !important;
	}


.categoria svg {
	width: 64px;
	margin-bottom: 10px;
}


.categoria path {
	fill: var(--gris-claro);
	transition: .3s ease all;
}

.categoria.activa {
	border: 2px solid var(--blue);
	color: #002858;
	box-shadow: 0px 3px 14px 0px #a0a0a0;

}

.categoria.activa path {
	fill: var(--primario);
}

.categoria a{
	font-size: 15px !important;
	font-weight: 700;
	color:  rgb(65, 119, 219) !important ;
}

.factu{
  display: flex;
  justify-content: space-between;
  padding: initial;
}

.factu li{
 list-style: none;
 padding: 5px;
}

i.bi.bi-trash3-fill{
  cursor: pointer;
  color: #ed3c0d;
  font-size: 16px;
}

.endFlex{
  border: none;
  display: flex;
  justify-content: flex-end;
  margin: 8px;
}

.centerGrid{
  display: grid;
  justify-content: center;
  justify-items: center;
}

.free{
  color: #39BA8F;
  font-size: 22px;
  font-weight: 550;
}


/*! =================================================*/
/*! ============= Contenedor Preguntas ============= */
/*! =================================================*/
.contenedor-preguntas {
	display: none;
	grid-template-columns: 1fr;

}

.contenedor-preguntas.activo {
	display: grid;
	justify-items: flex-start;
	align-items: flex-end;
	margin: 25px auto;
}

.contenedor-preguntas.activo h1{
	color: var(--blue);
}


#abrir{
	margin: 8px;
	width: 40px;
	color: #0075c9 !important;
}

.pregunta img{
width: 15px;	
}

.search{
    background-color: #1f8bbd;
    padding: 20px;
    margin: 30px;
    border-radius: 10px;
    border-collapse:separate;
  	position:relative;
    border : 1px solid gray;
    border-spacing: 50px;
   	color: #400040; 
    position: relative;
	font-family: 'Roboto', sans-serif;
   font-size: 20;
   border: 2px solid var(--primario);
  
  
}
.contenedor-pregunta {
	background: #fff;
	padding: 10px;
	border: 2px solid transparent;
	border-radius: 10px;
	overflow: hidden;
	opacity: 1;
  transition: opacity 3s ease; 
	cursor: pointer;
	box-shadow: 0px 3px 14px 0px #a0a0a0;
	display: grid;
   justify-items: center;
	height: fit-content;
  width: 290px;
}

.contenedor-pregunta:hover {
	box-shadow: 0px 3px 14px 0px #a0a0a0;
}

.contenedor-pregunta.activa {
	border: 2px solid var(--primario);
	box-shadow: 0px 3px 14px 0px #a0a0a0;
}

/*! =================================================*/
/*! ============= Preguntas ============= */
/*! =================================================*/
.pregunta {
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
}

.categoria img{
    fill: var(--light-blue);
    transition: .3s ease all;
	width: 64px;
    margin-bottom: 10px;
}

.contenedor-preguntas{	
	
	grid-template-columns: repeat(4, 1fr);

}


.pregunta img {
	width: 14px;
}


.respuesta {
	color: #808080;
	line-height: 30px;
	max-height: 0;
	opacity: 0;
	transition: .3s ease all;
}

.pedido.activa .respuesta {
	opacity: 1;
	margin-top: 20px;
}

.pedido.activa img {
	transform: rotate(45deg);
}

.pedido h3{

	color: #1565c0;

}
/*! =================================================*/
/*! ============= Responsive Design ============= */
/*! =================================================*/


@media screen and (max-width: 870px){
  .categorias {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 15px;
  
    margin-left: 65px;
  }
  .pagetitle h1 {

    justify-content: center;
}

	.new-label {
		right: 0px !important;
	}

	.btn-flotante{
		display: none !important;
	}
	#logo{
		display: none;
	}
	#logoMenu{
		display: none;
	}
	.navbar .navbar_right{
		display: none !important;
	}
	.navbars a{
		display: none;
	}


	p.pregunta{
		font-size: 15px;
		font-family: Arial, sans-serif;
	}
	p.respuesta{
		font-size: 14px;
		font-family: Roboto, Helvetica, Arial, sans-serif;

	}


	

	
	#largo{
		width: 100px !important;
		align-self: flex-start;
		
	   }
	
}


.gris{
  color: var(--gris) !important;
}

.img{ 
  width: 100px;
  height: 100px;
}

.error404{

  position: absolute;
  top: 100px;
  left: 150px;
}

.nombre{
  color:#858796 !important;
  font-size: 16px !important;
  letter-spacing: 2px;
  padding: 8px;
  width: 110%;
  font-weight: 600;

  text-align: right;
  background: linear-gradient(to right, #f6c23eee, #cda45e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Quicksand", serif;

}

.ant-radio-button-wrapper-checked {
  background-color: #cda45e !important; /* Cambia el color de fondo */
  border-color: #cda45e !important; /* Cambia el color del borde */
  color: white !important; /* Cambia el color del texto */
}

.ant-radio-button-wrapper-checked:hover {
  border-color: #cda45e !important; /* Cambia el color del borde al pasar el mouse */
  color: #f1f1f1 !important; /* Cambia el color del texto al pasar el mouse */
}

.ant-radio-button-wrapper:hover {
  color: var(--marron)!important; /* Cambia el color del texto al pasar el mouse */
}

.ant-radio-button-wrapper-checked::before {
  border-color: #cda45e !important; /* Cambia el color del borde antes de estar activo */
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #cda45e;
}

.adminPrice{
  padding-left: 4px;
  color: var(--second);
  font-size: 20px !important;
  font-weight: 650;
   font-family: "Glegoo", sans-serif; 
}

.colorSecondary{
  color: var(--second) !important; 
}


.observacion{
  padding-left: 4px;
  color: var(--primaryAdmi);
  font-size: 17px !important;
  font-weight: 900;
}
.rigth{
display: flex;
justify-content: flex-end;
}

.yellow{
  background: var(--yellow) !important;
  /* color: var(--dark-grey) !important; */
  font-weight: 600 !important;
  border-color: var(--yellow) !important;
}



.pricing-item {
  background-color: var(--surface-color);
  box-shadow: 0px -1px 5px 0px #858796;
  padding: 30px 30px;
  height: 100%;
  position: relative;
  border-radius: 15px;
}
.pricing h2 {
 
  margin-bottom: 12px;
  font-size: 18px;
  
}



.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.pricing .icon {
  margin: 32px auto 24px auto;
  width: 70px;
  height: 70px;
  background: var(--yellow);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform-style: preserve-3d;
}

.pricing .icon::before {
  position: absolute;
  content: "";
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background: 
color-mix(in srgb, var(--yellow), transparent 80%);
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}


.pricing h4 {
  font-size: 31px;
  color: var(--yellow);
  font-weight: 700;
  font-family: var(--heading-font);
  margin-bottom: 25px;
  text-align: center;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
 

  text-align: left;
  line-height: 20px;
}
.pricing .buy-btn {
 background: var(--opaco);
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  border: 1px solid 
color-mix(in srgb,#f1f1f1, transparent 80%);
  transition: none;
  font-size: 16px;
  font-weight: 700;
  color:var(--yellow);
  transition: 0.3s;
  box-shadow:  inset 0px -1px 6px 0px var(--yellow);
}

.pricing .buy-btn:hover{
  background-color: var(--dark-grey) !important;
  box-shadow:   0px 0px 6px 0px var(--yellow);
}
.pricing .icon i {
  color: var(--background-color);
  font-size: 28px;
  transition: ease-in-out 0.3s;
  line-height: 0;
}
.pricing .featured {
  transform: scale(1.04);
  box-shadow: 0px -2px 6px 0px var(--yellow);
}
.pricing .icon::after {
  position: absolute;
  content: "";
  height: 102px;
  width: 102px;
  border-radius: 50%;
  background: 
color-mix(in srgb, var(--yellow), transparent 90%);
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-2px);
}
.pricing ul {
  padding: 0 0;
  list-style: none;

  text-align: left;
  line-height: 20px;
}
.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pricing ul i {
  color: #cda45e;
  font-size: 24px;
  padding-right: 3px;
}
.pricing ul .na {
  color: 
color-mix(in srgb,#97afc6, transparent 70%);
}
.pricing ul .na span {
  text-decoration: line-through;
}
.pricing ul .na i {
  color: 
color-mix(in srgb,#97afc6, transparent 70%);
}

.modal-header-admi {
  border-bottom: inset var(--second)!important;
} 

.bg-white-custom {
  background: white;
}
.section-titleAdmi h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #bab3a6;
  margin: 4px 10px;
}

.blurred-background {
  position: absolute; /* Posiciona el div de fondo */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 
  background-size: cover; /* Asegura que la imagen cubra todo el div */
  filter: blur(10px); /* Ajusta el nivel de desenfoque */
  z-index: 1; /* Coloca el fondo detrás del contenido */
}


.css-es6bie {
  box-sizing: border-box;
  display: flex;
  margin-top: -20px;
  width: calc(100% + 140px);
  margin-left: -20px;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
}

.css-es6bie > .MuiGrid-item {
  padding-left: 10px;
  margin-left: 5px;
}

.css-es6bie > .MuiGrid-item {
 

  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  border-radius: 10px;
}

@media (min-width: 1536px) {
  .css-1s7a4db {
      flex-basis: 25%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 25%;
  }
}

@media (min-width: 1200px) {
  .css-1s7a4db {
      flex-basis: 50%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 50%;
  }
}

@media (min-width: 900px) {
  .css-1s7a4db {
      flex-basis: 50%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 50%;
  }
}

@media (min-width: 600px) {
  .css-1s7a4db {
      flex-basis: 50%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 50%;
  }
}

.css-1s7a4db {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
}

.css-rslm4y {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(27, 37, 89);
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px 28px;
  border-radius: 12px;
}

.css-19t5b1o {
  margin: 0px;
  font-size: 0.875rem;
 
  line-height: 1.43;
  color: rgb(163, 174, 208);
  font-weight: 500;
  text-align: start;
}

.css-lrcjg2 {
  margin: 8px 0px 0px;
  font-size: 1.75rem;
  font-weight: 700;
 
  line-height: 1.167;
  text-align: start;
}

.css-v8sqh {
  margin: 4px 0px 0px;
  font-size: 1.5rem;
  font-weight: 700;
 
  line-height: 1.235;
  text-align: start;
}

.css-v4qsmk {
  width: 75px;
  height: 68px !important;
}

.echarts-for-react {
  overflow: hidden;
}

.css-rslm4y {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(27, 37, 89);
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 10px 14px;
  border-radius: 12px;
}

.css-1ap9ute {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: #6c757d;
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 12px;
}
.css-278k3a {
  display: flex;
  flex-direction: row;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.css-nkftsk {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(27, 37, 89);
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  margin-left: 6px;
  padding-left: 16px;
  padding-right: 14px;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 12px;
  background: linear-gradient(135deg, var(--second) 0%,  var(--primaryAdmi)  100%);
}

.css-1skutlr {
  margin: 0px;
  font-size: 0.875rem;
 
  line-height: 1.43;
  color: rgb(233, 237, 247);
  font-weight: 500;
  text-align: start;
}

.css-gysky2 {
  margin: 8px 0px 0px;
  font-size: 1.75rem;
  font-weight: 700;
 
  line-height: 1.167;
  color: rgb(255, 255, 255);
}

.css-v4qsmk {
  width: 75px;
  height: 68px !important;
}


.echarts-for-react {
  overflow: hidden;
}

.css-1qni6z8 {
  max-width: 100%;
 
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: unset;
  vertical-align: middle;
  box-sizing: border-box;
  font-weight: 700;
  height: 28px;
  font-size: 0.875rem;
  color: rgb(5, 205, 153);
  background-color: rgba(5, 205, 153, 0.1);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: 10px 0px;
  padding: 0px 10px;
}

.css-vty6po {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--second) 0%,  var(--primaryAdmi)  100%);
}

.css-1stwjqg {
  color: rgb(255, 255, 255);
  font-size: 1.75rem;
}

.css-1rsidz7 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-weight: 550 !important;
}

.css-7zbfc4 {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  background-color:var(--gris);
  border-radius: 50%;
}

.css-131zg17 {
  color: var(--primaryAdmi);
  font-size: 1.75rem;
}

.css-e24385 {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: #858796;
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 8px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 12px;
}



.css-1r0g6ic {
  color: rgb(27, 37, 89);
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 28px;
  border-radius: 12px;
}

@media (min-width: 600px) {
  .css-1ym788i {
      -webkit-box-align: center;
      align-items: center;
  }
}

@media (min-width: 0px) {
  .css-1ym788i {
      align-items: flex-start;
  }
}

.css-1ym788i {
  display: flex
;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .css-14de4cz {
      flex-direction: row;
      gap: 12px;
  }
}


@media (min-width: 0px) {
  .css-14de4cz {
      flex-direction: column;
      gap: 6px;
  }
}

.css-14de4cz {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
}

.css-bxpo38 {
  margin: 0px;
  font-size: 1.5rem;
  font-weight: 700;
 
  line-height: 1.235;
  color: rgb(27, 37, 89);
}

@media (min-width: 600px) {
  .css-8qcydu {
      margin-top: 4.4px;
  }
}


@media (min-width: 0px) {
  .css-8qcydu {
      margin-top: 0px;
  }
}

.css-8qcydu {
  display: flex
;
  flex-direction: row;
  gap: 4px;
  -webkit-box-align: center;
  align-items: center;
}

.css-1nzz4nu {
  color: rgb(5, 205, 153);
  font-size: 1.125rem;
}

.css-odkaru {
  margin: 0px;
  font-size: 1rem;
 
  line-height: 1.5;
  color: rgb(5, 205, 153);
  font-weight: 700;
}

.css-1dthcdz {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  width: 105px;
}

.css-lhom0n.MuiInputBase-root {
  border: none;
  background-color: transparent !important;
}


.css-lhom0n {
  font-weight: 400;
 
  line-height: 1.4375em;
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(43, 54, 116);
  letter-spacing: 0.5px;
  position: relative;
  background-color: rgb(255, 255, 255) !important;
  border-width: 1px;
  border-image: initial;
  border-style: solid;
  border-color: rgb(244, 247, 254);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  border-radius: 8px;
  padding: 0px;
}
.css-incp5c.css-incp5c {
  padding-right: 32px;
}

.MuiSelect-select {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  font-size: 0.875rem;
  color: rgb(163, 174, 208);
  font-weight: 600;
  padding-right: 0px !important;
  background-color: transparent !important;
}

.css-incp5c.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.css-incp5c {
  appearance: none;
  user-select: none;
  border-radius: 0px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 0px;
}

.edit:hover{
  color: var(--second) !important;
}

.login-background {
  position: absolute;
  overflow: hidden;
  border-radius: 5px;
  top: 0;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: 20%;
  
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transform: translate(-50%);
  background: linear-gradient(90deg, var(--second) , var(--primaryAdmi));
  min-height: 300px;
  max-height: 770px;
  clip-path: polygon(0% 0, 0% 90%, 120% 0%);
}